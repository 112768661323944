<template>
  <div v-if="getGCalAccessStatus === 'denied'">
    <div class="px-4">
      <v-card class="py-6 px-6" color="white">
        <v-card-title>
          <v-avatar rounded="0">
            <v-img
              width="70"
              :src="require('../assets/google_calendar_icon.png')"
            ></v-img>
          </v-avatar>
          <span color="black"> Google Calendar</span>
        </v-card-title>

        <v-card-text>
          <p class="mt-6">
            wotMatters allows you to link your calendar events to other
            information, such as your notes and links. When you start a meeting,
            wotMatters will show all of this together, so you have everything at
            your fingertips.
          </p>
          <p class="mt-6">
            To get started, please allow wotMatters to read your Google
            Calendar.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="ml-2"
            variant="flat"
            color="primary"
            @click="startGoogleCalAuthorize"
          >
            Allow
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>

  <div v-else-if="getGCalAccessStatus === 'pending'" class="text-center">
    <v-icon color="white" size="x-large" class="mt-12"> mdi-calendar </v-icon>
    <p style="max-width: 200px; margin: auto; color: white" class="mt-6"></p>

    <v-progress-circular
      class="mt-6"
      model-value="20"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <div
    v-else-if="getGCalAccessStatus === 'granted'"
    style="overflow-x: hidden; overflow-y: auto; height: calc(100vh - 130px)"
  >
    <VueCal
      :events="calEvents"
      active-view="day"
      :disable-views="['years', 'year', 'month', 'week']"
      :selected-date="selectedDate"
      events-count-on-year-view
      events-on-month-view="short"
      :time-step="60"
      :on-event-click="onEventClick"
      class="vuecal--blue-theme"
      hide-view-selector
      watchRealTime
      showAllDayEvents="true"
      id="vuecal"
      :time-cell-height="timeCellHeight"
      @ready="scrollToCurrentTime"
    >
      <template #title="{ view }">
        <h3>
          {{ moment(view.endDate).format("dddd") }}
        </h3>
        <h6>
          {{ moment(view.endDate).format("D MMM YYYY") }}
        </h6>
      </template>
    </VueCal>

    <v-menu
      v-if="showDialog"
      v-model="showDialog"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
      nudge-right="5"
      min-width="250"
      max-width="450"
    >
      <v-card>
        <v-sheet style="padding-bottom: 30px; border-radius: 0" color="primary">
          <div class="text-center">
            <h3 class="pa-3" style="font-weight: 400">
              {{ selectedEvent.name }}
            </h3>
          </div>
        </v-sheet>

        <div class="text-center">
          <v-avatar style="margin-top: -30px">
            <v-icon v-if="selectedEvent.organizerImageUri == ''" size="x-large"
              >mdi-account-circle</v-icon
            >
            <v-img v-else :src="selectedEvent.organizerImageUri" />
          </v-avatar>
          <div class="v-card__subtitle pt-0">
            {{ selectedEvent.organizer }}
          </div>
          <h3 style="font-weight: 400">
            {{ formatDateforTime(selectedEvent.start) }} -
            {{ formatDateforTime(selectedEvent.end) }}
          </h3>
          <div class="v-card__subtitle pt-0">
            {{ selectedEvent.location }}
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="linkedTaskId"
                label="Linked task"
                auto-select-first
                clearable
                :items="getAllTasks"
                item-text="title"
                item-value="id"
                @update:modelValue="
                  setCalendarLinkedTask(selectedEvent, linkedTaskId)
                "
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row class="mb-2 ml-2 mr-2 justify-center">
            <v-btn
              class="mr-4"
              height="46"
              text
              @click="openNewWindow(selectedEvent.htmlLink)"
            >
              <v-icon class="mr-2">mdi-open-in-new</v-icon>
              OPEN
            </v-btn>

            <v-btn
              v-if="
                !getVideoConferenceEntryPoint(
                  selectedEvent.conferenceEntryPoints
                )
              "
              height="46"
              @click="startEvent(selectedEvent, linkedTaskId)"
            >
              <v-icon class="mr-2">mdi-text</v-icon>
              EVENT NOTES
            </v-btn>

            <v-menu v-else bottom offset-y>
              <template v-slot:activator="{ props }">
                <v-btn-toggle>
                  <v-btn
                    @click="
                      createNoteAndStartConference(selectedEvent, linkedTaskId)
                    "
                  >
                    <v-img
                      contain
                      max-height="24"
                      max-width="24"
                      :src="selectedEvent.conferenceSolutionIconUri"
                      class="mr-2"
                    />
                    {{ selectedEvent.conferenceSolutionName }}
                  </v-btn>

                  <v-btn v-bind="props">
                    <v-icon size="large">mdi-menu-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>

              <v-list color="#272727">
                <v-list-item @click="startEvent(selectedEvent, linkedTaskId)">
                  <v-icon class="mr-2">mdi-text</v-icon>
                  <span class="body-2">EVENT NOTES</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import router from "../router";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";

export default {
  created: function () {
    this.moment = moment;
  },
  props: {},
  components: {
    VueCal,
  },
  emits: [
    "changeBadge",
    "updateDrawerOpen",
    "updateDrawerSelected",
    "updateAutoCreateNote",
  ],
  data() {
    return {
      selectedSidebarButton: "",
      focussedJournalNoteId: "",

      // VUECAL
      showDialog: false,
      selectedEvent: null,
      selectedElement: null,
      linkedTaskId: null,
      // timeCellHeight: 26,
      selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      timeCellHeight: 52,
      refreshCalendarInterval: null,
      events: [],
    };
  },
  watch: {
    getGCalAccessStatus(newValue) {
      // When we've loaded an access token, load calendar events
      if (newValue === "granted") {
        if (!this.refreshCalendarInterval) {
          this.listCalendar();

          this.refreshCalendarInterval = setInterval(() => {
            this.listCalendar();
          }, 300000);
        }
      }
    },
    getCalendarEvents(newValue) {
      this.events = [];
      if (newValue) {
        this.events = this.loadEventsArray(newValue.items);
        this.updateEventBadgeContent();

        // Set date visible on calendar
        this.selectedDate = moment(new Date()).format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("googleapi", ["getGCalAccessStatus", "getCalendarEvents"]),
    ...mapGetters("task", ["getAllTasks"]),
    ...mapGetters("settings", ["getDriveAppFolder", "getDriveAppFolderName"]),
    ...mapGetters("ui", ["getCurrentDateString"]),
    // Events for display on the VueCal component
    calEvents() {
      var calEvents = [];
      if (this.events) {
        this.events.forEach((item) => {
          calEvents.push({
            id: item.id,
            title: item.name,
            start: item.start,
            end: item.end,
          });
        });
      }
      return calEvents;
    },
    // Events loaded from Google Calendar into my object model

    // events() {
    //   // this.getCalendarEvents().then((results) => {
    //     var eventsArray = [];
    //     console.log("updating Events");

    //     if (results) {
    //       eventsArray = this.loadEventsArray(results.items);
    //     }
    //     //  else {
    //     //   return [];
    //     // }
    //     // this.updateEventBadgeContent();
    //     return eventsArray;
    //   // });
    // },
    // eventBadgeContent() {
    //   var retVal = "NONE";
    //   const currDateTime = this.getCurrentDateString;

    //   console.log("setting eventbadgecontent");

    //   for (var i = 0; i < this.events.length; i++) {
    //     // Check for All Day Event
    //     // if (moment(this.events[i].start).format("YYYY-MM-DD") <= this.cal.times.now.date
    //     //   && moment(this.events[i].end).format("YYYY-MM-DD") >= this.cal.times.now.date
    //     //   && this.events[i].start.length <= 10) {
    //     //   retVal = "Now"
    //     //   break
    //     // }
    //     // Check for meeting underway
    //     console.log("event i=" + i);
    //     console.log(this.events[i]);
    //     if (
    //       this.events[i].start <= currDateTime &&
    //       this.events[i].end > currDateTime
    //     ) {
    //       console.log("set to NOW");
    //       retVal = "Now";
    //       break;
    //     }
    //     // Check for meeting later today
    //     else if (
    //       moment(this.events[i].start).format("YYYY-MM-DD") ==
    //         this.selectedDate &&
    //       currDateTime <= this.events[i].start
    //     ) {
    //       const inHours = moment
    //         .duration(moment(this.events[i].start).diff(currDateTime))
    //         .hours();
    //       const inMins = moment
    //         .duration(moment(this.events[i].start).diff(currDateTime))
    //         .minutes();

    //       console.log("set to " + (inHours > 0 ? inHours + "h" : inMins + "m"));
    //       retVal = inHours > 0 ? inHours + "h" : inMins + "m";
    //       break;
    //     }
    //   }
    //   console.log("emitting changebadge...");
    //   this.$emit("changeBadge", retVal);
    //   return retVal;
    // },
  },
  methods: {
    ...mapActions("googleapi", ["startGoogleCalAuthorize", "listCalendar"]),
    ...mapActions("task", ["updateTask"]),

    // VUECAL
    onEventClick(item, nativeEvent) {
      const rootEventId = this.getRootCalendarEvent(item.id);

      // Get selected task Id
      const selectedGcalEventTask = this.getAllTasks.find(
        (task) => task.events && task.events.includes(rootEventId)
      );
      if (selectedGcalEventTask) {
        this.linkedTaskId = selectedGcalEventTask.id;
      } else {
        this.linkedTaskId = null;
      }

      // Get Google Cal event for selected UI event
      const selectedGcalEventIndex = this.events.findIndex(
        (event) => event.id === item.id
      );
      if (selectedGcalEventIndex > -1) {
        this.selectedEvent = this.events[selectedGcalEventIndex];
      } else {
        this.selectedEvent = null;
      }
      this.selectedElement = nativeEvent.target;

      setTimeout(() => {
        // this.selectedOpen = true
        this.showDialog = true;
      }, 10);

      // Prevent navigating to narrower view (default vue-cal behavior).
      nativeEvent.stopPropagation();
    },
    scrollToCurrentTime() {
      const currentDate = new Date();

      const calendar = document.querySelector("#vuecal .vuecal__bg");
      const hours = currentDate.getHours() + currentDate.getMinutes() / 60;

      if (calendar) {
        calendar.scrollTo({
          top: hours * this.timeCellHeight,
          behavior: "smooth",
        });
      }
    },
    scrollToTop() {
      const calendar = document.querySelector("#vuecal .vuecal__bg");
      calendar.scrollTo({ top: 0, behavior: "smooth" });
    },
    loadEventsArray(newEvents) {
      // For calendar response details: https://developers.google.com/calendar/v3/reference/events
      var events = [];
      if (newEvents && newEvents.length > 0) {
        newEvents.forEach((item) => {
          if (
            this.getCurrentUser &&
            this.getMeetingResponse(
              item.attendees,
              this.getCurrentUser.email
            ) != "declined"
          ) {
            events.push({
              name: item.summary,
              start: item.start.date
                ? moment(item.start.date).format("YYYY-MM-DD")
                : moment(item.start.dateTime).format("YYYY-MM-DD HH:mm"),
              end: item.start.date
                ? moment(item.end.date).format("YYYY-MM-DD")
                : moment(item.end.dateTime).format("YYYY-MM-DD HH:mm"),
              allDay: item.start.date,
              id: item.id,
              // name:
              //   !item.summary || item.summary.trim() == ""
              //     ? "(No title)"
              //     : item.summary,
              // // name: item.summary.trim() == '' ? '(No title)' : item.summary,
              location: item.location,
              // start: item.start.date
              //   ? moment(item.start.date).format("YYYY-MM-DD")
              //   : moment(item.start.dateTime).format("YYYY-MM-DD HH:mm"),
              // end: item.end.date
              //   ? moment(item.end.date)
              //       .subtract(1, "second")
              //       .format("YYYY-MM-DD")
              //   : moment(item.end.dateTime).format("YYYY-MM-DD HH:mm"),
              // myResponse: this.getMeetingResponse(
              //   item.attendees,
              //   this.getCurrentUser.email
              // ),
              organizer: item.organizer.displayName
                ? item.organizer.displayName
                : item.organizer.email,
              organizerImageUri: "", // TODO: INCLUDE LOOKUP FOR PROFILE IMAGE
              htmlLink: item.htmlLink,
              // description: item.description,
              // attendees: item.attendees,
              conferenceSolutionIconUri:
                item.conferenceData && item.conferenceData.conferenceSolution
                  ? item.conferenceData.conferenceSolution.iconUri
                  : "",
              conferenceSolutionName:
                item.conferenceData && item.conferenceData.conferenceSolution
                  ? item.conferenceData.conferenceSolution.name
                  : "",
              conferenceEntryPoints:
                item.conferenceData && item.conferenceData.entryPoints
                  ? item.conferenceData.entryPoints
                  : [],
              // widthPc: 0,
              // processed: false,
            });
            // if (item.location) {
            //   console.log(item.start.dateTime)
            //   console.log(item.location)
            // }
          }
        });
        return events;
      } else {
        return [];
      }
    },
    getRootCalendarEvent(eventId) {
      // This returns the id before an underscore (which is used as a separator, after which is the datetime of the instance of this event) - I want to apply to all instances
      const uScorePos = eventId.lastIndexOf("_");
      return uScorePos >= 0 ? eventId.substr(0, uScorePos) : eventId;
    },
    setCalendarLinkedTask(event, linkedTaskId) {
      const rootEventId = this.getRootCalendarEvent(event.id);

      // Update previously linked task (if any)
      const prevLinkedTask = this.getAllTasks.find(
        (task) => task.events && task.events.includes(rootEventId)
      );
      if (prevLinkedTask) {
        const eventIndex = prevLinkedTask.events.findIndex(
          (event) => event === rootEventId
        );

        if (eventIndex > -1) {
          prevLinkedTask.events.splice(eventIndex, 1);
        }

        // prevLinkedTask.events.splice(taskIndex, 1);
        // prevLinkedTask.event = "";
        // this.updateTask(prevLinkedTask);
      }

      // Update newly linked task
      const linkedTask = this.getAllTasks.find(
        (task) => task.id === linkedTaskId
      );
      if (linkedTask) {
        if (!linkedTask.events) {
          linkedTask.events = [];
        }
        linkedTask.events.push(rootEventId);
        // this.updateTask(linkedTask);
      }
      // }
    },
    getMeetingResponse(attendees, attendeeEmail) {
      if (!attendees) {
        return "accepted"; // No attendees, so it's my meeting and I cannot decline it
      } else {
        const attendeeIndex = attendees.findIndex(
          (attendee) => attendee.email === attendeeEmail
        );
        return attendeeIndex > -1
          ? attendees[attendeeIndex].responseStatus
          : null;
      }
    },
    formatDateforTime(inputDate) {
      return moment(inputDate).format("hh:mm");
    },
    openNewWindow(htmlLink) {
      window.open(htmlLink);
    },
    getVideoConferenceEntryPoint(conferenceEntryPoints) {
      return conferenceEntryPoints
        ? conferenceEntryPoints.find((item) => item.entryPointType == "video")
        : null;
    },
    // getRootCalendarEvent(eventId) {
    //   // This returns the id before an underscore (which is used as a separator, after which is the datetime of the instance of this event) - I want to apply to all instances
    //   if (eventId) {
    //     const uScorePos = eventId.lastIndexOf("_");
    //     return uScorePos >= 0 ? eventId.substr(0, uScorePos) : eventId;
    //   }
    // },
    startEvent(event, linkedTaskId) {
      this.showDialog = false;

      if (linkedTaskId) {
        this.$emit("updateDrawerOpen", false);
        router.push("/task/" + linkedTaskId + "?autoCreateNote=" + event.id);
      } else {
        this.$emit("updateDrawerSelected", "JOURNAL");
        this.$emit("updateAutoCreateNote", event.id);
      }
    },
    createNoteAndStartConference(event, linkedTaskId) {
      this.openNewWindow(
        this.getVideoConferenceEntryPoint(event.conferenceEntryPoints).uri
      );

      this.startEvent(event, linkedTaskId);
    },
    updateEventBadgeContent() {
      var retVal = "NONE";
      const currDateTime = this.getCurrentDateString;

      if (this.events) {
        for (var i = 0; i < this.events.length; i++) {
          // Check for All Day Event
          // if (moment(this.events[i].start).format("YYYY-MM-DD") <= this.cal.times.now.date
          //   && moment(this.events[i].end).format("YYYY-MM-DD") >= this.cal.times.now.date
          //   && this.events[i].start.length <= 10) {
          //   retVal = "Now"
          //   break
          // }
          // Check for meeting underway
          if (
            this.events[i].start <= currDateTime &&
            this.events[i].end > currDateTime
          ) {
            retVal = "Now";
            break;
          }
          // Check for meeting later today
          else if (
            moment(this.events[i].start).format("YYYY-MM-DD") ==
              this.selectedDate &&
            currDateTime <= this.events[i].start
          ) {
            const inHours = moment
              .duration(moment(this.events[i].start).diff(currDateTime))
              .hours();
            const inMins = moment
              .duration(moment(this.events[i].start).diff(currDateTime))
              .minutes();

            retVal = inHours > 0 ? inHours + "h" : inMins + "m";
            break;
          }
        }
      }
      this.$emit("changeBadge", retVal);
      return retVal;
    },
  },
  beforeUnmount() {
    if (this.refreshCalendarInterval) {
      clearInterval(this.refreshCalendarInterval);
    }
  },
};
</script>

<style>
/* Works on Firefox */
.vuecal__bg {
  scrollbar-width: thin;
  scrollbar-color: #999 transparent;
}

/* Works on Chrome, Edge, and Safari */
.vuecal__bg::-webkit-scrollbar {
  width: 10px;
}

.vuecal__bg::-webkit-scrollbar-track {
  background: transparent;
}

.vuecal__bg::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 5px;
  border: 1px solid #999;
}
</style>
