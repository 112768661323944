import { db } from "../../modules/firestore";
import { FieldValue, serverTimestamp } from "firebase/firestore";
import taskSortTypes from "../../constants/taskSortTypes.js";
import taskStateTypes from "../../constants/taskStateTypes.js";
import moment from "moment";

const state = {
  tasks: [],
  taskGroups: [],
  listeners: [],
  taskAttributeCache: [],
};

const getters = {
  getAllTasks: (state) => {
    return state.tasks;
  },
  getAllTaskSummaries: (state) => {
    return state.tasks.map((task) => ({ id: task.id, title: task.title }));
  },
  getTaskById: (state) => (id) => {
    const taskIndex = state.tasks.findIndex((task) => task.id === id);

    return taskIndex > -1 ? state.tasks[taskIndex] : null;
  },
  getTasksFromRoot: (state) => (taskId) => {
    return state.tasks.filter(
      (task) => task.rootTaskId && task.rootTaskId === taskId
    );
  },
  getAllTopLevelTasks: (state) => {
    return state.tasks.filter(
      (task) => !task.rootTaskId || task.rootTaskId == task.id
    );
  },
  getAllTasksForGroup: (_, getters) => (taskGroupId) => {
    return getters.getAllTasks.filter((task) => task.taskGroup === taskGroupId);
  },
  getTopLevelTasksForGroup: (_, getters) => (taskGroupId) => {
    return getters.getAllTopLevelTasks
      .filter((task) => task.taskGroup === taskGroupId)
      .sort((a, b) => a.sortIndex - b.sortIndex);
  },
  getAllTaskGroups: (state) => {
    // Sorts by sortIndex DESC as then it renders left to right in ASC order
    return state.taskGroups.sort((a, b) => {
      return a.sortIndex > b.sortIndex ? 1 : -1;
    });
  },
  getFirstTaskGroup: (state) => {
    return state.taskGroups.find((taskGrp) => taskGrp.sortIndex === 0);
  },
  getSortedTasksBySortType: () => (tasks, sortType) => {
    switch (sortType) {
      case taskSortTypes.TASK_SORT_ALPHABETICAL:
        tasks.sort((a, b) => {
          const titleA = a.title.toUpperCase(); // ignore upper and lowercase
          const titleB = b.title.toUpperCase(); // ignore upper and lowercase
          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          // titles must be equal
          return 0;
        });
        break;
      case taskSortTypes.TASK_SORT_IMPORTANT:
        tasks.sort((a, b) => {
          if (a.isImportant && !b.isImportant) {
            return -1;
          }
          if (!a.isImportant && b.isImportant) {
            return 1;
          }
          // both or neither are important
          return 0;
        });
        break;
    }
  },
  getSortedDescTasksBySortType:
    (_, getters) => (topLevelTask, tasks, sortType) => {
      let hasImportantDesc = false;
      let maxOverdueDays = null;

      switch (sortType) {
        case taskSortTypes.TASK_SORT_ALPHABETICAL:
          tasks.sort((a, b) => {
            const titleA = a.title.toUpperCase(); // ignore upper and lowercase
            const titleB = b.title.toUpperCase(); // ignore upper and lowercase
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            // titles must be equal
            return 0;
          });
          break;

        case taskSortTypes.TASK_SORT_IMPORTANT:
          hasImportantDesc = topLevelTask.isImportant;
          maxOverdueDays = topLevelTask.targetDate
            ? moment(topLevelTask.targetDate).diff(moment(), "days")
            : null;

          // If only 1 entry, use its values rather than trying to sort
          if (tasks.length == 1) {
            const overdueDays = tasks[0].targetDate
              ? moment(tasks[0].targetDate).diff(moment(), "days")
              : null;

            // If higher sort entry is higher priority than current max, set it to the max
            const values = getters.getTaskPriorityComparison(
              hasImportantDesc,
              maxOverdueDays,
              tasks[0].isImportant,
              overdueDays
            );

            return {
              hasImportantDesc: values.hasImportantDesc,
              maxOverdueDays: values.maxOverdueDays,
            };
          } else if (tasks.length > 1) {
            tasks.sort((a, b) => {
              const overdueDaysA = a.targetDate
                ? moment(a.targetDate).diff(moment(), "days")
                : null;
              const overdueDaysB = b.targetDate
                ? moment(b.targetDate).diff(moment(), "days")
                : null;

              // Decide sort order
              const sortValues = getters.getTaskPriorityComparison(
                a.isImportant,
                overdueDaysA,
                b.isImportant,
                overdueDaysB
              );

              // If higher sort entry is higher priority than current max, set it to the max
              const maxCheckValues = getters.getTaskPriorityComparison(
                hasImportantDesc,
                maxOverdueDays,
                sortValues.hasImportantDesc,
                sortValues.maxOverdueDays
              );

              if (maxCheckValues.sortAction == 1) {
                hasImportantDesc = maxCheckValues.hasImportantDesc;
                maxOverdueDays = maxCheckValues.maxOverdueDays;
              }

              // Return sort action
              return sortValues.sortAction;
            });
            break;
          }
      }
      return { hasImportantDesc, maxOverdueDays };
    },
  getTaskPriorityComparison:
    () => (isImportantA, overdueDaysA, isImportantB, overdueDaysB) => {
      // Both are important, return oldest
      // If one overdue days is null, make it appear lower in the list than those with a value
      if (isImportantA && isImportantB) {
        return {
          hasImportantDesc: true,
          maxOverdueDays:
            overdueDaysA == null
              ? overdueDaysB
              : overdueDaysB == null
              ? overdueDaysA
              : overdueDaysA < overdueDaysB
              ? overdueDaysA
              : overdueDaysB,
          sortAction:
            overdueDaysA == null
              ? 1
              : overdueDaysB == null
              ? -1
              : overdueDaysA < overdueDaysB
              ? -1
              : 1,
        };
        // Both are NOT important, return oldest
        // If one overdue days is null, make it appear lower in the list than those with a value
      } else if (!isImportantA && !isImportantB) {
        return {
          hasImportantDesc: false,
          maxOverdueDays:
            overdueDaysA == null
              ? overdueDaysB
              : overdueDaysB == null
              ? overdueDaysA
              : overdueDaysA < overdueDaysB
              ? overdueDaysA
              : overdueDaysB,
          sortAction:
            overdueDaysA == null
              ? 1
              : overdueDaysB == null
              ? -1
              : overdueDaysA < overdueDaysB
              ? -1
              : 1,
        };
        // One is important...
        // NB: Neither can be null to be viaible in overdue list, so above logic doesn't apply here

        // If important one has older date, return that
      } else if (isImportantA && overdueDaysA <= overdueDaysB) {
        return {
          hasImportantDesc: true,
          maxOverdueDays: overdueDaysA,
          sortAction: -1,
        };
      } else if (isImportantB && overdueDaysB <= overdueDaysA) {
        return {
          hasImportantDesc: true,
          maxOverdueDays: overdueDaysB,
          sortAction: 1,
        };
        // If unimportant one has 10 days older than important one, return that
        // } else if (!isImportantA && overdueDaysA + 90 < overdueDaysB) {
        //   return {
        //     hasImportantDesc: false,
        //     maxOverdueDays: overdueDaysA,
        //     sortAction: -1,
        //   };
        // } else if (!isImportantB && overdueDaysB + 20 < overdueDaysA) {
        //   return {
        //     hasImportantDesc: false,
        //     maxOverdueDays: overdueDaysB,
        //     sortAction: 1,
        //   };
        // Otherwise return the important one
      } else {
        return {
          hasImportantDesc: true,
          maxOverdueDays: isImportantA ? overdueDaysA : overdueDaysB,
          sortAction: isImportantA ? -1 : 1,
        };
      }
    },
  getAllTaskAttributeCache: () => {
    return state.taskAttributeCache;
  },
  getTaskAttributeCacheById: (state) => (id) => {
    const taskIndex = state.taskAttributeCache.findIndex(
      (task) => task.id === id
    );

    return taskIndex > -1 ? state.taskAttributeCache[taskIndex] : null;
  },
  getHasImportantChildTask: (state) => (id) => {
    // Find the number of child tasks, which are important
    var childImportantFound = false;

    for (const e of state.taskAttributeCache) {
      if (
        e.state != taskStateTypes.TASK_STATE_COMPLETE &&
        e.state != taskStateTypes.TASK_STATE_WONT_DO &&
        e.rootTaskId &&
        e.rootTaskId === id &&
        e.isImportant
      ) {
        childImportantFound = true;
        break;
      }
    }
    return childImportantFound;
  },
  getImportantDescendantTaskCount: (state, getters) => (parentTask) => {
    const descTasks = getters.getChildTasks(parentTask);
    var importantTaskCount = 0;

    descTasks.forEach((task) => {
      if (
        task.state != taskStateTypes.TASK_STATE_COMPLETE &&
        task.state != taskStateTypes.TASK_STATE_WONT_DO
      ) {
        if (task.isImportant) {
          importantTaskCount += 1;
        }
        // Search Children Important flags
        importantTaskCount += getters.getImportantDescendantTaskCount(task);
      }
    });
    return importantTaskCount;
  },
  // Returns -1 if no tasks
  getClosedDescendantTaskPc: (state, getters) => (parentTask) => {
    const descTasks = getters.getChildTasks(parentTask);
    var closedTaskCount = 0;

    descTasks.forEach((task) => {
      if (
        task.state == taskStateTypes.TASK_STATE_COMPLETE ||
        task.state == taskStateTypes.TASK_STATE_WONT_DO
      ) {
        closedTaskCount += 1;
      }
    });
    return descTasks && descTasks.length > 0
      ? (100 * closedTaskCount) / descTasks.length
      : -1;
  },
  // Get all tasks child or lower to the specified task id
  getDescendantTasks: (state, getters) => (parentTask) => {
    const descendants = [];

    const children = getters.getChildTasks(parentTask);
    descendants.push(...children);

    children.forEach((child) => {
      getters.getDescendantTasks(child);
    });

    return descendants;
  },
  // Get all tasks child or lower to the specified task id of important and active tasks
  getImportantActiveChildTasks: (state, getters) => (parentTask) => {
    return getters.getAllTasks.filter(
      (child) =>
        child.rootTaskId == parentTask.id &&
        child.isImportant &&
        child.state != taskStateTypes.TASK_STATE_COMPLETE &&
        child.state != taskStateTypes.TASK_STATE_WONT_DO
    );
  },
  // Get child tasks to the specified task id
  getChildTasks: (state) => (parentTask) => {
    const childTasks = state.tasks.filter(
      (childTask) => childTask.parentTaskId == parentTask.id
    );
    return childTasks;
  },
  // Get all child tasks to the specified task id using the treeeview structure
  getAllChildTreeTasks: (state, getters) => (parentTask) => {
    var childTasks = state.tasks.filter(
      (childTask) => parentTask && childTask.parentTaskId == parentTask.id
    );

    childTasks.forEach((childTask) => {
      const hasGrandChildTasks = state.tasks.some(
        (task) => task.parentTaskId == childTask.id
      );
      if (hasGrandChildTasks) {
        const grandChildTasks = getters.getAllChildTreeTasks(childTask);
        childTask.children = [];
        if (grandChildTasks && grandChildTasks.length > 0) {
          childTask.children.push(...grandChildTasks);
        }
      }
    });

    return childTasks;
  },
  // Get child tasks to the specified task id
  getTasksGroupColor: (state) => (task) => {
    const taskGroup = state.taskGroups.find(grp => grp.id == task.taskGroup)

    if (taskGroup) {
      return taskGroup.color;
    }
    else {
      return null;
    }
  },
};

const mutations = {
  // TASKS
  setTaskGroups(state, payload) {
    state.taskGroups = payload;
  },
  addTask(state, payload) {
    state.tasks.push(payload);
  },
  setTask(state, payload) {
    const taskIndex = state.tasks.findIndex((task) => task.id === payload.id);

    if (taskIndex > -1) {
      // Remove then add, otherwise Array change isn't triggered on client
      state.tasks.splice(taskIndex, 1, payload);
    }
  },
  removeTask(state, payload) {
    const taskIndex = state.tasks.findIndex((task) => task.id === payload.id);

    if (taskIndex > -1) {
      state.tasks.splice(taskIndex, 1);
    }
  },
  clearTasks(state) {
    state.tasks = [];
  },
  clearTaskGroups(state) {
    state.taskGroups = [];
  },

  // Task Attribute Cache
  addTaskAttributeCache(state, payload) {
    state.taskAttributeCache.push(payload);
  },
  setTaskAttributeCache(state, payload) {
    const taskIndex = state.taskAttributeCache.findIndex(
      (task) => task.id === payload.id
    );

    if (taskIndex > -1) {
      // Remove then add, otherwise Array change isn't triggered on client
      state.taskAttributeCache.splice(taskIndex, 1, payload);
    }
  },
  removeTaskAttributeCache(state, payload) {
    const taskIndex = state.taskAttributeCache.findIndex(
      (task) => task.id === payload.id
    );

    if (taskIndex > -1) {
      state.taskAttributeCache.splice(taskIndex, 1);
    }
  },
  clearTaskAttributeCache(state) {
    state.taskAttributeCache = [];
  },

  // TASK LINKS
  // addTaskLink(state, payload) {
  //   state.openTaskLinks.push(payload);
  // },
  // setTaskLink(state, payload) {
  //   const linkIndex = state.openTaskLinks.findIndex(
  //     (link) => link.id === payload.id
  //   );

  //   if (linkIndex > -1) {
  //     // Remove then add, otherwise Array change isn't triggered on client
  //     state.openTaskLinks.splice(linkIndex, 1, payload);
  //   }
  // },
  // removeTaskLink(state, payload) {
  //   const linkIndex = state.openTaskLinks.findIndex(
  //     (link) => link.id === payload.id
  //   );

  //   if (linkIndex > -1) {
  //     state.openTaskLinks.splice(linkIndex, 1);
  //   }
  // },
  // clearTaskLinks(state) {
  //   state.openTaskLinks = [];
  // },

  // // TASK NOTES
  // addTaskNote(state, payload) {
  //   state.openTaskNotes.push(payload)
  // },
  // setTaskNote(state, payload) {
  //   const noteIndex = state.openTaskNotes.findIndex(note => note.id === payload.id);

  //   if (noteIndex > -1) {
  //     // Remove then add, otherwise Array change isn't triggered on client
  //     state.openTaskNotes.splice(noteIndex, 1, payload)
  //   }
  // },
  // removeTaskNote(state, payload) {
  //   const noteIndex = state.openTaskNotes.findIndex(note => note.id === payload.id);

  //   if (noteIndex > -1) {
  //     state.openTaskNotes.splice(noteIndex, 1)
  //   }
  // },
  // clearTaskNotes(state) {
  //   state.openTaskNotes = []
  // },

  // // TASKS ASSIGNED TO OPEN TASKS
  // addTaskAssignedTask(state, payload) {
  //   state.openTaskAssignedTasks.push(payload)
  // },
  // setTaskAssignedTask(state, payload) {
  //   const taskIndex = state.openTaskAssignedTasks.findIndex(task => task.id === payload.id);

  //   if (taskIndex > -1) {
  //     // Remove then add, otherwise Array change isn't triggered on client
  //     state.openTaskAssignedTasks.splice(taskIndex, 1, payload)
  //   }
  // },
  // removeTaskAssignedTask(state, payload) {
  //   const taskIndex = state.openTaskAssignedTasks.findIndex(task => task.id === payload.id);

  //   if (taskIndex > -1) {
  //     state.openTaskAssignedTasks.splice(taskIndex, 1)
  //   }
  // },
  // clearTaskAssignedTasks(state) {
  //   state.openTaskAssignedTasks = []
  // },

  // // JOURNAL NOTES
  // addJournalNote(state, payload) {
  //   state.openJournalNotes.push(payload);
  // },
  // setJournalNote(state, payload) {
  //   const noteIndex = state.openJournalNotes.findIndex(note => note.id === payload.id);

  //   if (noteIndex > -1) {
  //     // Remove then add, otherwise Array change isn't triggered on client
  //     state.openJournalNotes.splice(noteIndex, 1, payload)
  //   }
  // },
  // removeJournalNote(state, payload) {
  //   const noteIndex = state.openJournalNotes.findIndex(note => note.id === payload.id);

  //   if (noteIndex > -1) {
  //     state.openJournalNotes.splice(noteIndex, 1)
  //   }
  // },
  // clearJournalNotes(state) {
  //   state.openJournalNotes = []
  // },

  // LISTENERS
  setListener(state, payload) {
    state.listeners.push(payload);
  },
  removeListener(state, index) {
    state.listeners.splice(index, 1);
  },
  removeAllListeners(state) {
    state.listeners = [];
  },
};

const actions = {
  deleteTask({ rootGetters, getters }, payload) {
    // Delete Task
    var taskDocRef = db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks")
      .doc(payload.id);
    taskDocRef.delete();

    // Delete descendant tasks
    const descendantTasks = getters.getDescendantTasks(payload);

    descendantTasks.forEach((doc) => {
      var taskDocRef = db
        .collection(
          "users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks"
        )
        .doc(doc.id);

      taskDocRef.delete();
    });
  },

  //       // Delete Notes for task
  //       db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').where('taskId', '==', payload)
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           // Update any Task to no longer reference this NoteId
  //           db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/').where('noteId', '==', doc.id)
  //           .get()
  //           .then((querySnapshot) => {
  //             querySnapshot.forEach((doc) => {
  //               var noteTaskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/').doc(doc.id);
  //               batch.update(noteTaskDocRef, { noteId: null, noteNodeId: null })
  //             })

  //             var noteDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').doc(doc.id);
  //             batch.delete(noteDocRef)

  //             // Delete Events linked to task
  //             db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/events/').where('linkedTaskId', '==', payload)
  //             .get()
  //             .then((querySnapshot) => {
  //               querySnapshot.forEach((doc) => {
  //                 var eventDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/events/').doc(doc.id);
  //                 batch.delete(eventDocRef)
  //               })
  //               batch.commit()
  //             })
  //             .catch((error) => {
  //               console.log("Error getting Event documents for task deletion: ", error);
  //             })
  //           })
  //           .catch((error) => {
  //             console.log("Error getting Event documents for Note Tasks setting: ", error);
  //           })
  //         })
  //         batch.commit()
  //       })
  //       .catch((error) => {
  //         console.log("Error getting Note documents for task deletion: ", error);
  //       })
  //     })
  //     .catch((error) => {
  //       console.log("Error getting Note documents for task deletion: ", error);
  //     })
  //   },
  updateTask({ rootGetters }, payload) {
    // Copy the object, so I can remove the ID without breaking the original object
    const taskCopy = JSON.parse(JSON.stringify(payload));
    const docId = taskCopy.id;
    delete taskCopy.id; // Removes the ID from the payload, as it is not stored at this level in Firebase
    delete taskCopy.created; // Removes the created, as it shouldn't be updated
    taskCopy.updated = serverTimestamp(); // update timestamp

    db.collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks")
      .doc(docId)
      .set(taskCopy, { merge: true });
  },
  updateTaskField({ rootGetters }, payload) {
    var taskRef = db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks")
      .doc(payload.taskId);

    // Set the "capital" field of the city 'DC'
    return taskRef
      .update(payload.updateObject) // eg. { title: 'fred' }
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  },
  addTask({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      db.collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks"
      )
        .add({
          title: payload.title ? payload.title : "",
          state: payload.state ? payload.state : "",
          isImportant: payload.isImportant ? payload.isImportant : false,
          taskGroup: payload.taskGroup ? payload.taskGroup : "",
          targetDate: payload.targetDate ? payload.targetDate : "",
          parentTaskId: payload.parentTaskId ? payload.parentTaskId : "",
          rootTaskId: payload.rootTaskId ? payload.rootTaskId : "",
          statusHistory: payload.statusHistory ? payload.statusHistory : [],
          created: serverTimestamp(),
        })
        .then((ref) => resolve(ref.id))
        .catch((error) => {
          console.log("Error saving Task: ", error);
          reject(error);
        });
    });
  },
  addTaskGroup({ rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      db.collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/taskGroups"
      )
        .add({
          title: payload.title ? payload.title : "",
          sortIndex: payload.sortIndex,
          created: serverTimestamp(),
        })
        .then((ref) => resolve(ref.id))
        .catch((error) => {
          console.log("Error saving Task Group: ", error);
          reject(error);
        });
    });
  },
  updateTaskGroup({ rootGetters }, payload) {
    // Copy the object, so I can remove the ID without breaking the original object
    const taskGroupCopy = JSON.parse(JSON.stringify(payload));
    const docId = taskGroupCopy.id;
    delete taskGroupCopy.id; // Removes the ID from the payload, as it is not stored at this level in Firebase
    delete taskGroupCopy.created; // Removes the created, as it shouldn't be updated
    taskGroupCopy.updated = serverTimestamp(); // update timestamp

    db.collection(
      "users/" + rootGetters["auth/getCurrentUser"].uid + "/taskGroups"
    )
      .doc(docId)
      .set(taskGroupCopy, { merge: true });
  },
  updateTaskGroupField({ rootGetters }, payload) {
    var taskGroupRef = db
      .collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/taskGroups"
      )
      .doc(payload.taskGroupId);

    // Set the "capital" field of the city 'DC'
    return taskGroupRef
      .update(payload.updateObject) // eg. { title: 'fred' }
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  },
  deleteTaskGroup({ rootGetters, dispatch }, payload) {
    // Delete Task Group
    var taskGroupDocRef = db
      .collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/taskGroups"
      )
      .doc(payload.id);
    taskGroupDocRef.delete();

    // Update other Task Groups Sort Index
    for (
      let i = payload.sortIndex + 1;
      i <= rootGetters["task/getAllTaskGroups"].length;
      i++
    ) {
      const impactedTaskGroup = rootGetters["task/getAllTaskGroups"].find(
        (group) => group.sortIndex === i
      );
      if (impactedTaskGroup) {
        impactedTaskGroup.sortIndex -= 1;
        // Update DB
        dispatch("updateTaskGroup", impactedTaskGroup);
      }
    }
  },
  setTaskListener({ commit, rootGetters }) {
    commit("clearTasks");
    commit("clearTaskAttributeCache");

    const listener = db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks")
      .onSnapshot(
        (tasksRef) => {
          tasksRef.docChanges().forEach((change) => {
            if (change.type === "added") {
              commit("addTaskAttributeCache", {
                id: change.doc.id,
                isImportant: change.doc.data().isImportant,
                state: change.doc.data().state,
                parentTaskId: change.doc.data().parentTaskId,
                rootTaskId: change.doc.data().rootTaskId,
              });

              // Add Task
              commit("addTask", { id: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "modified") {
              commit("setTaskAttributeCache", {
                id: change.doc.id,
                isImportant: change.doc.data().isImportant,
                state: change.doc.data().state,
                parentTaskId: change.doc.data().parentTaskId,
                rootTaskId: change.doc.data().rootTaskId,
              });

              // Update Task
              commit("setTask", { id: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "removed") {
              commit("removeTaskAttributeCache", {
                id: change.doc.id,
              });

              // Remove Task
              commit("removeTask", { id: change.doc.id });
            }
          });
        },
        (error) => {
          console.log("Snapshot error");
          console.log(error);
        }
      );
    // store listener so it can be closed (eg. when logged out)
    commit("setListener", { type: "task", listener: listener });
  },
  setTaskGroupListener({ commit, rootGetters }) {
    commit("clearTaskGroups");

    const listener = db
      .collection(
        "users/" + rootGetters["auth/getCurrentUser"].uid + "/taskGroups"
      )
      // TODO: Use this approach rather than replace entire list of tasks each time - this requires some significant refactoring of naming etc.
      // .onSnapshot((tasksRef) => {
      // console.log("Processing task listener...")
      // tasksRef.docChanges().forEach(change => {
      //     if (change.type === 'added') {
      //       commit("addTask", { id: change.doc.id, ...change.doc.data() });
      //     }
      //     if (change.type === 'modified') {
      //       commit("setTask", { id: change.doc.id, ...change.doc.data() });
      //     }
      //     if (change.type === 'removed') {
      //       commit("removeTask", { id: change.doc.id });
      //     }
      //   });
      // });
      .onSnapshot(
        (taskRef) => {
          const taskGroups = [];
          taskRef.forEach((doc) => {
            const taskGroup = doc.data();
            taskGroup.id = doc.id;

            taskGroups.push(taskGroup);
          });
          commit("setTaskGroups", taskGroups);
        },
        (error) => {
          console.log("Snapshot error");
          console.log(error);
        }
      );
    // store listener so it can be closed (eg. when logged out)
    commit("setListener", { type: "taskGroup", listener: listener });
  },
  //   setLinksListener({ commit, rootGetters }, taskId) {
  //     const listener = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links')
  //     .onSnapshot((linksRef) => {
  //       linksRef.docChanges().forEach(change => {
  //         if (change.type === 'added') {
  //           commit("addTaskLink", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'modified') {
  //           commit("setTaskLink", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'removed') {
  //           commit("removeTaskLink", { id: change.doc.id });
  //         }
  //       });
  //     });
  //     // store listener so it can be closed (eg. when logged out)
  //     commit("setListener", { 'type': 'links', 'listener': listener });
  //   },
  //   setNotesListener({ commit, rootGetters }, taskId) {
  //     const listener = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').where('taskId', '==', taskId).orderBy('created', 'desc').limit(20)
  //     .onSnapshot((notessRef) => {
  //       notessRef.docChanges().forEach(change => {
  //         if (change.type === 'added') {
  //           commit("addTaskNote", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'modified') {
  //           commit("setTaskNote", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'removed') {
  //           commit("removeTaskNote", { id: change.doc.id });
  //         }
  //       });
  //     });
  //     // store listener so it can be closed (eg. when logged out)
  //     commit("setListener", { 'type': 'notes', 'listener': listener });
  //   },
  //   setAssignedTasksListener({ commit, rootGetters }, taskId) {
  //     const listener = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/').orderBy('created', 'desc')
  //       .where('parentTaskIds', 'array-contains-any', [ taskId ])
  //     .onSnapshot((assignedTasksRef) => {
  //       assignedTasksRef.docChanges().forEach(change => {
  //         if (change.type === 'added') {
  //           commit("addTaskAssignedTask", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'modified') {
  //           commit("setTaskAssignedTask", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'removed') {
  //           commit("removeTaskAssignedTask", { id: change.doc.id });
  //         }
  //       });
  //     });
  //     // store listener so it can be closed (eg. when logged out)
  //     commit("setListener", { 'type': 'assignedTasks', 'listener': listener });
  //   },
  //   setJournalNotesListener({ commit, rootGetters }, taskId) {
  //     const listener = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').where('taskId', '==', taskId).orderBy('created', 'desc').limit(20)
  //     .onSnapshot((journalNotessRef) => {
  //       journalNotessRef.docChanges().forEach(change => {
  //         if (change.type === 'added') {
  //           commit("addJournalNote", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'modified') {
  //           commit("setJournalNote", { id: change.doc.id, ...change.doc.data() });
  //         }
  //         if (change.type === 'removed') {
  //           commit("removeJournalNote", { id: change.doc.id });
  //         }
  //       });
  //     });
  //     // store listener so it can be closed (eg. when logged out)
  //     commit("setListener", { 'type': 'journalNotes', 'listener': listener });
  //   },
  stopTaskListener({ commit, state }, listenerType) {
    const lIndex = state.listeners.findIndex((l) => l.type === listenerType);
    if (lIndex > -1) {
      const listener = state.listeners.find((l) => l.type == listenerType);
      if (listener) {
        listener.listener();
      }
      commit("removeListener", lIndex);
    }
  },
  stopAllTaskListeners({ commit, state }) {
    for (let listener of state.listeners) {
      listener.listener();
    }
    commit("removeAllListeners");
  },
  //   addURLLink({ rootGetters }, { taskId, url, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'] }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').add({ url, title, showInMainUI, displayState })
  //   },
  //   // addGDriveLink({ rootGetters }, { taskId, fileId, mimeType, thumbnailLink, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'], thumbnailDisplayType }) {
  //   //   db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').add({ fileId, mimeType, thumbnailLink, title, showInMainUI, displayState, thumbnailDisplayType })
  //   // },
  //   addImageLink({ rootGetters }, { taskId, imageData, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'], imageDisplayType }) {
  //     getThumbnailSizeForBase64Img(imageData, 350, 200).then((result) => {
  //       resizeBase64Img(imageData, result.outputWidth, result.outputHeight).then((result)=>{
  //         db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').add({ imageData: result, title, showInMainUI, displayState, imageDisplayType })
  //       });
  //     })

  //   },
  //   deleteLink({ rootGetters }, { taskId, linkId }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(linkId).delete()
  //   },
  //   setURLLink({ rootGetters }, { taskId, id, url, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'] }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id).set({ url, title, showInMainUI, displayState })
  //   },
  //   // setGDriveLink({ rootGetters }, { taskId, id, fileId, mimeType, thumbnailLink, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'] }) {
  //   //   db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id).set({ fileId, mimeType, thumbnailLink, title, showInMainUI, displayState })
  //   // },
  //   setImageLink({ rootGetters }, { taskId, id, imageData, title, showInMainUI = true, displayState = this.$constants()['LINK_DISPLAY_OFF'], imageDisplayType }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id).set({ imageData, title, showInMainUI, displayState, imageDisplayType })
  //   },
  //   setImageLinkDisplayType({ rootGetters }, { taskId, id, imageData, imageDisplayType }) {
  //     var batch = db.batch();

  //     // HACK: I am getting an error when I reference constants from within the action login, so I've hartdcoded them
  //     // Update Link thumbnail display type
  //     var linkUpdateDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id);
  //     console.log("UPDATING LINK ID=" + id + " to " + imageDisplayType)
  //     batch.update(linkUpdateDocRef, { imageDisplayType });

  //     if (imageDisplayType == "avatar") {
  //       var taskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //       taskDocRef.get().then((doc) => {
  //         // If this thumbnail link was in the carousel and is now standard, remove it from being in the carousel
  //         if (doc.data().carouselImages) {
  //           var newCarouselArray = doc.data().carouselImages;

  //           const linkIndex = newCarouselArray.indexOf(imageData);
  //           if (linkIndex > -1) {
  //             // Remove linkfrom list
  //             newCarouselArray.splice(linkIndex, 1);
  //             batch.update(taskDocRef, {
  //               carouselImages: newCarouselArray
  //             });
  //           }
  //         }
  //         // Update Task Avatar thumbnail URL
  //         batch.update(taskDocRef, { avatarImage: imageData });

  //         // Clear any other Link's Avatar URL
  //         db.collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks/" + taskId + "/links/")
  //         .get()
  //         .then((querySnapshot) => {
  //           querySnapshot.forEach((doc) => {
  //             if (doc.id != id && doc.data().imageDisplayType == "avatar") {
  //               var linkDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links/').doc(doc.id);
  //               batch.update(linkDocRef, { imageDisplayType: "std" })
  //             }
  //           }
  //         });
  //         batch.commit();
  //       })
  //     }
  //     else if (imageDisplayType == "carousel") {
  //       var carouselTaskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //       carouselTaskDocRef.get().then((doc) => {
  //         // If this thumbnail link was an avatar and is now a carousel, remove it from being the avatar
  //         if (doc.data() && doc.data().avatarImage == imageData) {
  //           batch.update(carouselTaskDocRef, { avatarImage: "" });
  //         }

  //         // Update the carousel array with this link
  //         if (!doc.data().carouselImages) {
  //           var newArray = [imageData]
  //           batch.update(carouselTaskDocRef, { carouselImages: newArray });
  //         }
  //         else if (!doc.data().carouselImages.includes(imageData)) {
  //           var newCarouselArray = doc.data().carouselImages;
  //           newCarouselArray.push(imageData)
  //           batch.update(carouselTaskDocRef, { carouselImages: newCarouselArray });
  //         }
  //         batch.commit()
  //       })
  //     }
  //     else {
  //       var stdTaskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //       stdTaskDocRef.get().then((doc) => {
  //         // If this thumbnail link was an avatar and is now standard, remove it from being the avatar
  //         if (doc.data() && doc.data().avatarImage == imageData) {
  //           batch.update(stdTaskDocRef, { avatarImage: "" });
  //         }
  //         // If this thumbnail link was in the carousel and is now standard, remove it from being in the carousel
  //         if (doc.data().carouselImages) {
  //           var newCarouselArray = doc.data().carouselImages;

  //           const linkIndex = newCarouselArray.indexOf(imageData);
  //           if (linkIndex > -1) {
  //             // Remove linkfrom list
  //             newCarouselArray.splice(linkIndex, 1);
  //             batch.update(stdTaskDocRef, { carouselImages: newCarouselArray });
  //           }
  //         }
  //         batch.commit();
  //       });
  //     }
  //   },
  //   // setGDriveLinkThumbnailDisplayType({ rootGetters }, { taskId, id, thumbnailLink, thumbnailDisplayType = this.$constants()['LINK_THUMBNAIL_DISPLAY_STD'] }) {
  //   //   var batch = db.batch();

  //   //   // HACK: I am getting an error when I reference constants from within the action login, so I've hartdcoded them
  //   //   // Update Link thumbnail display type
  //   //   var linkUpdateDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id);
  //   //   batch.update(linkUpdateDocRef, { thumbnailDisplayType })

  //   //   if (thumbnailDisplayType == "avatar") {
  //   //     // Update Task Avatar thumbnail URL
  //   //     var taskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //   //     taskDocRef
  //   //     .get()
  //   //     .then((doc) => {
  //   //       // If this thumbnail link was in the carousel and is now standard, remove it from being in the carousel
  //   //       if (doc.data().carouselThumbnailLinks) {
  //   //         var newCarouselArray = doc.data().carouselThumbnailLinks

  //   //         const linkIndex = newCarouselArray.indexOf(thumbnailLink);
  //   //         if (linkIndex > -1) {
  //   //           // Remove linkfrom list
  //   //           newCarouselArray.splice(linkIndex, 1)
  //   //           batch.update(taskDocRef, { carouselThumbnailLinks: newCarouselArray })
  //   //         }
  //   //       }

  //   //       batch.update(taskDocRef, { avatarThumbnailLink: thumbnailLink })

  //   //       // Clear any other Link's Avatar thumbnail URL
  //   //       db.collection('users/' + rootGetters['auth/getCurrentUser'].email +  '/tasks/' + taskId + '/links/')
  //   //       .get()
  //   //       .then((querySnapshot) => {
  //   //         querySnapshot.forEach((doc) => {
  //   //           if (doc.id != id && doc.data().thumbnailDisplayType == "avatar") {
  //   //             var linkDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links/').doc(doc.id);
  //   //             batch.update(linkDocRef, { thumbnailDisplayType: "std" })
  //   //           }
  //   //         })

  //   //         batch.commit()
  //   //       })
  //   //     })
  //   //   }
  //   //   else if (thumbnailDisplayType == "carousel") {
  //   //     // Update Task Avatar thumbnail URL
  //   //     var carouselTaskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //   //     carouselTaskDocRef
  //   //     .get()
  //   //     .then((doc) => {
  //   //       // If this thumbnail link was an avatar and is now a carousel, remove it from being the avatar
  //   //       if (doc.data() && doc.data().avatarThumbnailLink == thumbnailLink) {
  //   //         batch.update(carouselTaskDocRef, { avatarThumbnailLink: "" })
  //   //       }

  //   //       // Update the carousel array with this link
  //   //       if (!doc.data().carouselThumbnailLinks) {
  //   //         var newArray = [thumbnailLink]
  //   //         batch.update(carouselTaskDocRef, { carouselThumbnailLinks: newArray })
  //   //       }
  //   //       else if (!doc.data().carouselThumbnailLinks.includes(thumbnailLink)) {
  //   //         var newCarouselArray = doc.data().carouselThumbnailLinks
  //   //         newCarouselArray.push(thumbnailLink)
  //   //         batch.update(carouselTaskDocRef, { carouselThumbnailLinks: newCarouselArray })
  //   //       }
  //   //       batch.commit()
  //   //     })
  //   //   }
  //   //   else {
  //   //     var stdTaskDocRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks').doc(taskId);
  //   //     stdTaskDocRef
  //   //     .get()
  //   //     .then((doc) => {
  //   //       // If this thumbnail link was an avatar and is now standard, remove it from being the avatar
  //   //       if (doc.data() && doc.data().avatarThumbnailLink == thumbnailLink) {
  //   //         batch.update(stdTaskDocRef, { avatarThumbnailLink: "" })
  //   //       }
  //   //       // If this thumbnail link was in the carousel and is now standard, remove it from being in the carousel
  //   //       if (doc.data().carouselThumbnailLinks) {
  //   //         var newCarouselArray = doc.data().carouselThumbnailLinks

  //   //         const linkIndex = newCarouselArray.indexOf(thumbnailLink);
  //   //         if (linkIndex > -1) {
  //   //           // Remove linkfrom list
  //   //           newCarouselArray.splice(linkIndex, 1)
  //   //           batch.update(stdTaskDocRef, { carouselThumbnailLinks: newCarouselArray })
  //   //         }
  //   //       }

  //   //       batch.commit()
  //   //     })
  //   //   }
  //   // },
  //   setLinkArchived({ rootGetters }, { taskId, id, displayState = this.$constants()['LINK_DISPLAY_OFF'] }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/links').doc(id).set({ displayState }, { merge: true })
  //   },
  async addNote(
    { rootGetters },
    {
      taskId,
      title = "(untitled)",
      attendees = [],
      content,
      eventId = null,
      eventDate = null,
    }
  ) {
    const res = await db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/notes")
      .add({
        taskId,
        eventId,
        eventDate,
        title,
        attendees,
        content,
        created: FieldValue.serverTimestamp(),
      });

    // Update new document with field containing the doc ID - this is requred to search based on the doc id in the notes collectionGroup
    // ie. it doesn't have support for doc id in the query (ref: https://stackoverflow.com/questions/56188250/how-to-perform-collection-group-query-using-document-id-in-cloud-firestore)
    // await db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/tasks/' + taskId + '/notes').doc(res.id).update({ noteId: res.id })
    return res;
  },
  deleteNote({ rootGetters }, { noteId }) {
    var batch = db.batch();

    // Delete Note
    var noteDocRef = db
      .collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/notes")
      .doc(noteId);
    batch.delete(noteDocRef);

    // Update any Task to no longer reference this NoteId - this is to make sure we don't lose any task data
    db.collection("users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks/")
      .where("noteId", "==", noteId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var noteTaskDocRef = db
            .collection(
              "users/" + rootGetters["auth/getCurrentUser"].uid + "/tasks/"
            )
            .doc(doc.id);
          batch.update(noteTaskDocRef, { noteId: null, noteNodeId: null });
        });

        batch.commit();
      })
      .catch((error) => {
        console.log("Error getting Task documents for note deletion: ", error);
      });
  },
  //   setNote({ rootGetters }, { id, content, eventId = null }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').doc(id)
  //     .set({ eventId, content }, { merge: true })
  //   },
  //   setNoteTitle({ rootGetters }, { id, title = "(untitled)" }) {
  //     db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes').doc(id)
  //     .set({ title }, { merge: true })
  //   },
  //   moveNote({ rootGetters }, { taskId, note, targetTaskId }) {
  //     var batch = db.batch()

  //     if (note.eventId) {
  //       // Get root calendar event id
  //       // This returns the id before an underscore (which is used as a separator, after which is the datetime of the instance of this event) - I want to apply to all instances
  //       // NB: This is a copy of the method in SideBarCalendar and could be refactored to a common function
  //       const uScorePos = note.eventId.lastIndexOf("_")
  //       const rootEventId = uScorePos >= 0
  //         ? note.eventId.substr(0, uScorePos)
  //         : note.eventId

  //       // If the note was created from an Event, create event record for future event notes to be linked to the target task
  //       // For now, we're assuming this is only being called for moving from the journal, not from another task - ie. no event record exists yet
  //       const eventDocAddRef = db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/events/').doc(rootEventId);
  //       batch.set(eventDocAddRef, { linkedTaskId: targetTaskId })

  //       // Get all notes created from this event id
  //       // Workaround for a LIKE statement (ref: https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search)
  //       // The character \uf8ff used in the query is a very high code point in the Unicode range (it is a Private Usage Area [PUA] code).
  //       // Because it is after most regular characters in Unicode, the query matches all values that start with queryText.
  //       var docCount = 0
  //       db.collection('users/' + rootGetters['auth/getCurrentUser'].email + '/notes')
  //       .where('eventId', '>=', rootEventId)
  //       .where('eventId', '<=', rootEventId + '\uf8ff')
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           docCount ++
  //           moveNoteEntity(rootGetters['auth/getCurrentUser'].email, batch, taskId, {id: doc.id, ...doc.data()}, targetTaskId, docCount == querySnapshot.size)
  //         })
  //       })
  //       .catch((error) => {
  //           console.log("Error getting Note documents for event for note movement: ", error);
  //       })
  //     }
  //     else {
  //       // Just move the passed Note
  //       moveNoteEntity(rootGetters['auth/getCurrentUser'].email, batch, taskId, note, targetTaskId, true)
  //     }
  //   },
  // };

  // function moveNoteEntity(userEmail, batch, taskId, note, targetTaskId, commitBatch ) {
  //   // Update TaskId for Note to target TaskId
  //   const noteDocRef = db.collection('users/' + userEmail + '/notes').doc(note.id);
  //   batch.update(noteDocRef, { taskId: targetTaskId })

  //   // Find tasks created by this note with a parent of the source taskId, so they can be moved to the target taskId
  //   db.collection('users/' + userEmail + '/tasks/')
  //   .where('noteId', '==', note.id)
  //   .where('parentTaskIds', "array-contains", taskId)
  //   .get()
  //   .then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       const foundTask = doc.data();
  //       const noteTaskDocRef = db.collection('users/' + userEmail + '/tasks/').doc(doc.id);

  //       // Update parentTaskId for any tasks which are a child of the moving task
  //       const parentIndex = foundTask.parentTaskIds.indexOf(taskId);

  //       // Replace with targetTaskId
  //       var newParentTaskIds = foundTask.parentTaskIds
  //       newParentTaskIds.splice(parentIndex, 1, targetTaskId)
  //       batch.update(noteTaskDocRef, { parentTaskIds: newParentTaskIds })
  //     })

  //     if (commitBatch) {
  //       batch.commit()
  //     }
  //   })
  //   .catch((error) => {
  //       console.log("Error getting Task documents for note movement: ", error);
  //   })
  // }

  // function getThumbnailSizeForBase64Img(base64, maxWidth, maxHeight) {
  //   return new Promise((resolve) => {
  //     // Load image to identify original dimensions
  //     var outputWidth = 0;
  //     var outputHeight = 0;
  //     var img = new Image()
  //     img.onload = function() {
  //       var widthRatio = 1;  // Used for aspect ratio
  //       var heightRatio = 1;  // Used for aspect ratio
  //       var bestRatio = 0;
  //       var width = img.width;    // Current image width
  //       var height = img.height;  // Current image height

  //       // Check if the current width is larger than the max
  //       // if(width > maxWidth){
  //         widthRatio = maxWidth / width;   // get ratio for scaling image
  //       // }

  //       // Check if current height is larger than max
  //       // if(height > maxHeight){
  //         heightRatio = maxHeight / height; // get ratio for scaling image
  //       // }

  //       // Best Ratio is one that has the same or greater width/height sizes (so there's no empty space)
  //       bestRatio = widthRatio >= heightRatio ? heightRatio : widthRatio

  //       outputHeight = height * bestRatio;   // Set new height
  //       outputWidth = width * bestRatio;    // Scale width based on ratio

  //       resolve({ outputWidth, outputHeight });
  //     }
  //     img.src=base64
  //   })
};

/**
 * Resize a base 64 Image
 * @param {String} base64 - The base64 string (must include MIME type)
 * @param {Number} newWidth - The width of the image in pixels
 * @param {Number} newHeight - The height of the image in pixels
 */
// function resizeBase64Img(base64, newWidth, newHeight) {
//   return new Promise((resolve)=>{
//       var canvas = document.createElement("canvas");
//       canvas.style.width = newWidth.toString()+"px";
//       canvas.style.height = newHeight.toString()+"px";
//       let context = canvas.getContext("2d");
//       let img = document.createElement("img");
//       img.src = base64;
//       img.onload = function () {
//           context.scale(newWidth/img.width,  newHeight/img.height);
//           context.drawImage(img, 0, 0);
//           resolve(canvas.toDataURL());
//       }
//   });
// }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
