// Task Constants
const taskSortTypes = {
  TASK_SORT_IMPORTANT: "TaskSortImportant",
  TASK_SORT_ALPHABETICAL: "TaskSortAlphabetical",
};

taskSortTypes.Mixin = {
  created() {
    this.taskSortTypes = taskSortTypes;
  },
};

export default taskSortTypes;
