import moment from "moment";
import taskStateTypes from "../../constants/taskStateTypes";
import taskGroupColorTypes from "../../constants/taskGroupColorTypes.js";
import taskSortTypes from "../../constants/taskSortTypes";

const state = {
  snackbarDisplayed: false,
  snackbarText: "",
  drawerOpen: false,
  currentDate: "",
  CEWarnClosed: false,
  DPWarnClosed: false,
  deferredPrompt: null,
  navBarVisible: false,
  viewMode: [taskStateTypes.TASK_STATE_OPEN, taskStateTypes.TASK_STATE_WAITING],
  colorMode: [
    taskGroupColorTypes.TASK_GROUP_COLOR_DEFAULT,
    taskGroupColorTypes.TASK_GROUP_COLOR_1,
    taskGroupColorTypes.TASK_GROUP_COLOR_2,
    taskGroupColorTypes.TASK_GROUP_COLOR_3,
    taskGroupColorTypes.TASK_GROUP_COLOR_4,
  ],
  sortMode: taskSortTypes.TASK_SORT_IMPORTANT,
  wotMattersMode: null,
};

const getters = {
  getSnackbarDisplayed(state) {
    return state.snackbarDisplayed;
  },
  getSnackbarText(state) {
    return state.snackbarText;
  },

  getDrawerOpen(state) {
    return state.drawerOpen;
  },
  getCurrentDate() {
    return moment().startOf("day");
  },
  getCurrentDateString(state) {
    return moment(state.currentDate).format("YYYY-MM-DD HH:mm");
  },
  getCEWarnClosed(state) {
    return state.CEWarnClosed;
  },
  getDeferredPrompt(state) {
    return state.deferredPrompt;
  },
  getDPWarnClosed(state) {
    return state.DPWarnClosed;
  },
  getNavBarVisible(state) {
    return state.navBarVisible;
  },
  getViewMode(state) {
    return state.viewMode;
  },
  getColorMode(state) {
    return state.colorMode;
  },
  getSortMode(state) {
    return state.sortMode;
  },
  getWotMattersMode(state) {
    return state.wotMattersMode;
  },
};

const mutations = {
  setSnackbarDisplayed(state, value) {
    state.snackbarDisplayed = value;
  },
  setSnackbarText(state, value) {
    state.snackbarText = value;
  },

  setDrawerOpen(state, value) {
    state.drawerOpen = value;
  },
  setCurrentTimeInterval(state) {
    state.currentDate = moment();

    state.currentTimeInterval = setInterval(() => {
      state.currentDate = moment();
    }, 1000 * 60);
  },
  clearCurrentTimeInterval(state) {
    state.currentTimeInterval = null;
  },
  setCEWarnClosed(state, value) {
    state.CEWarnClosed = value;
  },
  setDeferredPrompt(state, value) {
    state.deferredPrompt = value;
  },
  setDPWarnClosed(state, value) {
    state.DPWarnClosed = value;
  },
  setNavBarVisible(state, value) {
    state.navBarVisible = value;
    // When hiding sidebar, close it as well
    if (!value) {
      state.drawerOpen = value;
    }
  },
  setViewMode(state, value) {
    state.viewMode = value;
  },
  setColorMode(state, value) {
    state.colorMode = value;
  },
  setSortMode(state, value) {
    state.sortMode = value;
  },
  setWotMattersMode(state, value) {
    state.wotMattersMode = value;
  },
};

const actions = {
  showSnackbar(context, text) {
    context.commit("setSnackbarText", text);
    context.commit("setSnackbarDisplayed", true);
  },
  startCurrentTimeInterval(context) {
    context.commit("setCurrentTimeInterval");
  },
  stopCurrentTimeInterval(context) {
    context.commit("clearCurrentTimeInterval");
  },
  toggleViewMode({ commit, getters }, viewMode) {
    const viewArray = getters.getViewMode;
    const viewModeIndex = viewArray.indexOf(viewMode);

    if (viewModeIndex > -1) {
      viewArray.splice(viewModeIndex, 1);
      commit("setViewMode", viewArray);
    } else {
      viewArray.push(viewMode);
      commit("setViewMode", viewArray);
    }
  },
  toggleColorMode({ commit, getters }, color) {
    const colorArray = getters.getColorMode;
    const colorModeIndex = colorArray.indexOf(color);

    if (colorModeIndex > -1) {
      colorArray.splice(colorModeIndex, 1);
      commit("setColorMode", colorArray);
    } else {
      colorArray.push(color);
      commit("setColorMode", colorArray);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
