import { db } from "../../modules/firestore";
// import firebase from "firebase/compat/app";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // ======================
  // Data Migration methods
  // ======================
  // Remove: children, noteNodeId, originatingFile, labels, sortPrevTask
  // Move status to statusHistory and remove status
  runMigration() {
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((user) => {
          // var userTasks = [];
          db.collection("users/" + user.id + "/tasks")
            .get()
            .then((qs2) => {
              qs2.forEach((task) => {
                if (!task.data().state) {
                  console.log(task.id)
                }
                // Move completed to State
                // const newState = task.completed ? "complete" : "open";

                // db.collection("users/" + user.id + "/tasks")
                //   .doc(task.id)
                //   .set({ state: newState }, { merge: true });

                // Move status to statusHistory
                // if (task.data().status && task.data().status.length > 0) {
                //   const statusHistory = [];
                //   const statusObject = {
                //     text: task.data().status,
                //     occuranceDate: "2025-02-03",
                //   };
                //   statusHistory.push(statusObject);

                //   db.collection("users/" + user.id + "/tasks")
                //     .doc(task.id)
                //     .set({ statusHistory: statusHistory }, { merge: true });
                // }
                // // Create Parent Task Id
                // if (task.data().labels && task.data().labels.length > 0) {
                //   const taskIndex = task
                //     .data()
                //     .labels.findIndex((label) => label.type == "task");
                //   if (taskIndex > -1) {
                //     console.log("migrating " + task.id);

                //     db.collection("users/" + user.id + "/tasks")
                //       .doc(task.id)
                //       .set(
                //         { parentTaskId: task.data().labels[taskIndex].id },
                //         { merge: true }
                //       );

                //     // Add to full list of tasks array
                //     userTasks.push({
                //       id: task.id,
                //       parentTaskId: task.data().labels[taskIndex].id,
                //     });
                //   } else {
                //     userTasks.push({
                //       id: task.id,
                //       parentTaskId: -1,
                //     });
                //   }
                // } else {
                //   userTasks.push({
                //     id: task.id,
                //     parentTaskId: -1,
                //   });
                // }
                // Delete Label
                // db.collection("users/" + user.id + "/tasks")
                //   .doc(task.id)
                //   .update({
                //     completed: firebase.firestore.FieldValue.delete(),
                //     // children: firebase.firestore.FieldValue.delete(),
                //     // noteNodeId: firebase.firestore.FieldValue.delete(),
                //     // originatingFile: firebase.firestore.FieldValue.delete(),
                //     // labels: firebase.firestore.FieldValue.delete(),
                //     // sortPrevTask: firebase.firestore.FieldValue.delete(),
                //     // status: firebase.firestore.FieldValue.delete(),
                //   });

                // db.collection("users/" + user.id + "/tasks")
                //   .doc(task.id)
                //   .update({
                //     labels: [],
                //   });
              });

              // userTasks.forEach((uTask) => {
              //   console.log("processing " + uTask.id);

              //   var parentTask;
              //   var parentTaskId = uTask.parentTaskId;

              //   if (parentTaskId != -1) {
              //     while (parentTaskId != -1) {
              //       parentTask = userTasks.find(
              //         (pTask) => pTask.id == parentTaskId
              //       );
              //       if (parentTask) {
              //         parentTaskId = parentTask.parentTaskId ?? -1;
              //       } else {
              //         console.log("cant find ", parentTaskId);
              //         parentTaskId = -1;
              //       }
              //     }
              //     console.log(
              //       "setting task " + uTask.id + " to " + parentTask.id
              //     );
              //     db.collection("users/" + user.id + "/tasks")
              //       .doc(uTask.id)
              //       .set({ rootTaskId: parentTask.id }, { merge: true });
              //   }
              // });
            });
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
