// Task Constants
const taskStateTypes = {
  TASK_STATE_OPEN: "open",
  TASK_STATE_COMPLETE: "complete",
  TASK_STATE_WONT_DO: "wont do",
  TASK_STATE_WAITING: "waiting",
};

taskStateTypes.Mixin = {
  created() {
    this.taskStateTypes = taskStateTypes;
  },
};

export default taskStateTypes;
