// Task Constants
const taskGroupColorTypes = {
  TASK_GROUP_COLOR_DEFAULT: "default",
  TASK_GROUP_COLOR_1: "indigo-darken-3",
  TASK_GROUP_COLOR_2: "pink-darken-4",
  TASK_GROUP_COLOR_3: "purple-darken-4",
  TASK_GROUP_COLOR_4: "teal-darken-3",
};

taskGroupColorTypes.Mixin = {
  created() {
    this.taskGroupColorTypes = taskGroupColorTypes;
  },
};

export default taskGroupColorTypes;
